<template>
  <container-app>
    <template #content>
      <card-basic>
        <template #content>
          <nav v-if="full_akses" class="mb-5">
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <button
                class="nav-link active"
                id="nav-dosen-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-dosen"
                type="button"
                role="tab"
                aria-controls="nav-dosen"
                aria-selected="false"
              >
                Dosen
              </button>
              <button
                class="nav-link"
                id="nav-penelitian-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-penelitian"
                type="button"
                role="tab"
                aria-controls="nav-penelitian"
                aria-selected="true"
              >
                Usulan PPM
              </button>
              <button
                class="nav-link"
                id="nav-reviewer-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-reviewer"
                type="button"
                role="tab"
                aria-controls="nav-reviewer"
                aria-selected="false"
              >
                Reviewer
              </button>
              <button
                class="nav-link"
                id="nav-form-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-form"
                type="button"
                role="tab"
                aria-controls="nav-form"
                aria-selected="false"
              >
                Form Penilaian
              </button>
              <button
                class="nav-link"
                id="nav-jadwal-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-jadwal"
                type="button"
                role="tab"
                aria-controls="nav-jadwal"
                aria-selected="false"
              >
                Jadwal
              </button>
              <button
                class="nav-link"
                id="nav-skema_iuran-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-skema_iuran"
                type="button"
                role="tab"
                aria-controls="nav-skema_iuran"
                aria-selected="false"
              >
                Skema Luaran
              </button>
              <button
                class="nav-link"
                id="nav-luaran_tambahan-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-luaran_tambahan"
                type="button"
                role="tab"
                aria-controls="nav-luaran_tambahan"
                aria-selected="false"
              >
                Luaran Tambahan
              </button>
            </div>
          </nav>

          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade"
              :class="!full_akses ? 'show active' : ''"
              id="nav-penelitian"
              role="tabpanel"
              aria-labelledby="nav-penelitian-tab"
            >
              <!-- filter semester -->
              <div
                class="d-flex flex-column flex-md-row justify-content-between gap-3 mb-5 mx-5"
              >
                <div class="d-flex align-items-center gap-2">
                  <label class="form-label m-0">Semester</label>
                  <select
                    class="form-select w-auto"
                    v-model="jadwal_id"
                    @change="indexPengusulan"
                  >
                    <option
                      v-for="jadwal in data_jadwal_pengusulan"
                      :key="jadwal.id"
                      :value="jadwal.id"
                    >
                      {{ jadwal.semester }}
                    </option>
                  </select>
                </div>

                <a
                  :href="base_url + 'download/pengusulan/' + jadwal_id"
                  target="_blank"
                  class="btn btn-danger"
                  v-if="
                    lembaga_jabatan == 'LPPM STAF' ||
                    lembaga_jabatan == 'LPPM KETUA'
                  "
                  >Download</a
                >
              </div>

              <table-basic>
                <template #thead>
                  <tr class="fw-bolder bg-light fs-7">
                    <th class="rounded-start ps-5 w-md-50px">No.</th>
                    <th class="w-md-200px">Dosen</th>
                    <th>Penelitian</th>
                    <th class="rounded-end pe-5 w-md-150px">Aksi</th>
                  </tr>
                </template>

                <template #tbody>
                  <tr
                    class="fs-7"
                    v-for="(pengusul, i) in data_pengusul"
                    :key="i"
                  >
                    <td class="text-end ps-5">{{ i + 1 }}</td>
                    <td>
                      <span class="fw-bold d-block">
                        {{ pengusul.gelar_depan }} {{ pengusul.nama }},
                        {{ pengusul.gelar_belakang }}</span
                      >
                      NIDN. {{ pengusul.nidn }}
                    </td>
                    <td>
                      <div
                        v-for="(data, i) in pengusul.data_usulan"
                        :key="i"
                        :class="i > 0 ? 'mt-6' : ''"
                      >
                        <div class="d-flex justify-content-between">
                          <div>
                            <span>Usulan {{ i + 1 }}</span>
                            <span class="badge badge-info mx-3">{{
                              data.jenis
                            }}</span>
                            <span
                              v-if="data.nonaktif != null"
                              class="badge badge-danger"
                              >Dinonaktifkan</span
                            >
                          </div>
                          <span
                            class="badge"
                            :class="
                              ['DISETUJUI', 'PERUBAHAN DISETUJUI'].includes(
                                data.status
                              )
                                ? 'badge-success'
                                : 'badge-secondary'
                            "
                            >{{ data.status }}
                          </span>
                        </div>
                        <p class="fw-bold mb-1">{{ data.judul }}</p>
                      </div>
                    </td>
                    <td class="pe-5">
                      <button
                        v-if="pengusul.jumlah_usulan > 0"
                        class="btn btn-sm btn-secondary"
                        data-bs-toggle="modal"
                        data-bs-target="#detailUsulanPPM"
                        @click="detailUsulan(i)"
                      >
                        Detail
                      </button>
                    </td>
                  </tr>
                </template>
              </table-basic>
            </div>

            <div
              class="tab-pane fade"
              :class="full_akses ? 'show active' : ''"
              id="nav-dosen"
              role="tabpanel"
              aria-labelledby="nav-dosen-tab"
            >
              <table-basic>
                <template #thead>
                  <tr class="fw-bolder bg-light fs-7">
                    <th class="rounded-start ps-5 w-md-50px">No.</th>
                    <th>Dosen</th>
                    <th>Program Studi</th>
                    <th>Kelompok Keahlian</th>
                    <th>Akses Penelitian</th>
                    <th class="rounded-end ps-5 w-md-110px">Aksi</th>
                  </tr>
                </template>

                <template #tbody>
                  <tr v-for="(dosen, i) in data_dosen" :key="i">
                    <td class="text-end">{{ i + 1 }}</td>
                    <td>
                      <span class="fw-bold d-block"
                        >{{ dosen.gelar_depan }} {{ dosen.nama }},
                        {{ dosen.gelar_belakang }}</span
                      >
                      NIDN. {{ dosen.nidn }}
                    </td>
                    <td>{{ dosen.program_studi }}</td>
                    <td>{{ dosen.kelompok_keahlian }}</td>
                    <td>
                      <span
                        class="badge badge-danger"
                        v-if="dosen.penelitian == 0"
                        >Belum Memiliki Akses</span
                      >
                      <span class="badge badge-success" v-else
                        >Memiliki Akses</span
                      >
                    </td>
                    <th class="pe-5">
                      <button
                        class="btn btn-sm btn-secondary"
                        data-bs-toggle="modal"
                        data-bs-target="#profilDosen"
                        @click="profilDosen(dosen.id)"
                      >
                        Validasi Profil
                      </button>
                      <button
                        class="btn btn-sm btn-secondary ms-2"
                        data-bs-toggle="modal"
                        data-bs-target="#editDosen"
                        @click="modalEditDosen(dosen.id)"
                      >
                        Edit
                      </button>
                    </th>
                  </tr>
                </template>
              </table-basic>
            </div>

            <div
              class="tab-pane fade"
              id="nav-reviewer"
              role="tabpanel"
              aria-labelledby="nav-reviewer-tab"
            >
              <div class="d-flex justify-content-end mb-5 me-5">
                <button
                  class="btn btn-sm btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#modalReviewer"
                  @click="form_reviewer = {}"
                >
                  Tambah
                </button>
              </div>

              <table-basic>
                <template #thead>
                  <tr class="fw-bolder bg-light fs-7">
                    <th class="rounded-start ps-5 w-md-50px">No.</th>
                    <th>Nama Dosen</th>
                    <th class="rounded-end ps-5 w-md-110px">Aksi</th>
                  </tr>
                </template>

                <template #tbody>
                  <tr
                    class="fs-7"
                    v-for="(reviewer, i) in data_reviewer"
                    :key="i"
                  >
                    <td class="text-end">{{ i + 1 }}</td>
                    <td>
                      <span class="fw-bold d-block">{{
                        reviewer.dosen_nama
                      }}</span>
                      NIDN. {{ reviewer.dosen_nidn }}
                    </td>
                    <td>
                      <button
                        class="btn btn-sm btn-danger"
                        @click="deleteReviewer(reviewer.id)"
                      >
                        Hapus
                      </button>
                    </td>
                  </tr>
                </template>
              </table-basic>
            </div>

            <div
              class="tab-pane fade"
              id="nav-form"
              role="tabpanel"
              aria-labelledby="nav-form-tab"
            >
              <div class="d-flex justify-content-end mb-5 me-5">
                <button
                  class="btn btn-sm btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#modalFormPenilaian"
                  @click="form_form_penilaian = {}"
                >
                  Tambah
                </button>
              </div>

              <table-basic>
                <template #thead>
                  <tr class="fw-bolder bg-light fs-7">
                    <th class="rounded-start ps-5 w-md-50px">No.</th>
                    <th>Soal</th>
                    <th>Bobot</th>
                    <th class="rounded-end ps-5 w-md-110px">Aksi</th>
                  </tr>
                </template>

                <template #tbody>
                  <tr v-for="(penilaian, i) in data_form_penilaian" :key="i">
                    <td class="text-end">{{ i + 1 }}</td>
                    <td>
                      <span class="fw-bold d-block">{{ penilaian.nama }}</span>
                      <span v-html="penilaian.keterangan"></span>
                    </td>
                    <td>{{ penilaian.bobot }}</td>
                    <td>
                      <button
                        class="btn btn-sm btn-secondary"
                        data-bs-toggle="modal"
                        data-bs-target="#modalFormPenilaian"
                        @click="showFormPenilaian(i)"
                      >
                        Ubah
                      </button>
                    </td>
                  </tr>
                </template>
              </table-basic>
            </div>

            <div
              class="tab-pane fade"
              id="nav-jadwal"
              role="tabpanel"
              aria-labelledby="nav-jadwal-tab"
            >
              <div class="d-flex justify-content-end mb-5 me-5">
                <button
                  class="btn btn-sm btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#modalJadwalPenelitian"
                  @click="form_jadwal = {}"
                >
                  Tambah
                </button>
              </div>

              <table-basic>
                <template #thead>
                  <tr class="fw-bolder bg-light fs-7">
                    <th class="rounded-start ps-5 w-md-50px">No.</th>
                    <th>Tahun Usulan</th>
                    <th>Semester</th>
                    <th>Jumlah Penelitian & PKM</th>
                    <th>Jumlah Anggota</th>
                    <th>Jumlah Reviewer</th>
                    <th>Status</th>
                    <th class="rounded-end ps-5 w-md-200px">Aksi</th>
                  </tr>
                </template>

                <template #tbody>
                  <tr v-for="(jadwal, i) in data_jadwal_pengusulan" :key="i">
                    <td class="ps-5">{{ i + 1 }}</td>
                    <td>{{ jadwal.tahun_usulan }}</td>
                    <td>{{ jadwal.semester }}</td>
                    <td>
                      {{ jadwal.jumlah_penelitian }} / {{ jadwal.jumlah_pkm }}
                    </td>
                    <td>{{ jadwal.jumlah_anggota }}</td>
                    <td>{{ jadwal.jumlah_reviewer }}</td>
                    <td>
                      <span
                        class="badge badge-success"
                        v-if="jadwal.status == 1"
                        >Aktif</span
                      >
                      <span class="badge badge-danger" v-else>Tidak Aktif</span>
                    </td>
                    <td>
                      <button
                        class="btn btn-sm me-2"
                        :class="
                          jadwal.status == 1 ? 'btn-danger' : 'btn-success'
                        "
                        @click="ubahJadwalPengusulan(jadwal.id)"
                      >
                        <span v-if="jadwal.status == 1">Matikan</span>
                        <span v-else>Aktifkan</span>
                      </button>
                      <button
                        class="btn btn-sm btn-secondary"
                        data-bs-toggle="modal"
                        data-bs-target="#modalJadwalPenelitian"
                        @click="showJadwalPenelitian(i)"
                      >
                        Ubah
                      </button>
                    </td>
                  </tr>
                </template>
              </table-basic>
            </div>

            <div
              class="tab-pane fade"
              id="nav-skema_iuran"
              role="tabpanel"
              aria-labelledby="nav-skema_iuran-tab"
            >
              <div class="d-flex justify-content-end mb-5 me-5">
                <button
                  class="btn btn-sm btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#modalTargetIuran"
                  @click="form_skema_iuran = {}"
                >
                  Tambah
                </button>
              </div>

              <table-basic>
                <template #thead>
                  <tr class="fw-bolder bg-light fs-7">
                    <th class="rounded-start ps-5 w-md-50px">No.</th>
                    <th>Target Luaran</th>
                    <th class="w-md-150px">Biaya</th>
                    <th>Dana Awal (%)</th>
                    <th class="rounded-end ps-5 w-md-200px">Aksi</th>
                  </tr>
                </template>

                <template #tbody>
                  <tr v-for="(iuran, i) in data_skema_iuran" :key="i">
                    <td class="ps-5">{{ i + 1 }}</td>
                    <td>{{ iuran.target_iuran }}</td>
                    <td>{{ formatRupiah(iuran.biaya) }}</td>
                    <td>{{ iuran.dana_awal }}</td>
                    <td>
                      <button
                        class="btn btn-sm btn-secondary"
                        data-bs-toggle="modal"
                        data-bs-target="#modalTargetIuran"
                        @click="showSkemaIuran(i)"
                      >
                        Ubah
                      </button>
                      <button
                        class="btn btn-sm btn-danger ms-2"
                        @click="deleteSkemaIuran(iuran.id)"
                      >
                        Hapus
                      </button>
                    </td>
                  </tr>
                </template>
              </table-basic>
            </div>

            <div
              class="tab-pane fade"
              id="nav-luaran_tambahan"
              role="tabpanel"
              aria-labelledby="nav-luaran_tambahan-tab"
            >
              <div class="d-flex justify-content-end mb-5 me-5">
                <button
                  class="btn btn-sm btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#modalFormLuaranTambahan"
                  @click="form_luaran_tambahan = {}"
                >
                  Tambah Luaran
                </button>
              </div>

              <table-basic>
                <template #thead>
                  <tr class="fw-bolder bg-light fs-7">
                    <th class="rounded-start ps-5 w-md-50px">No.</th>
                    <th>Luaran Tambahan</th>
                    <th>Kategori</th>
                    <th class="rounded-end ps-5 w-md-200px">Aksi</th>
                  </tr>
                </template>

                <template #tbody>
                  <tr v-for="(luaran, i) in data_luaran_tambahan" :key="i">
                    <td class="ps-5 text-end">{{ i + 1 }}</td>
                    <td>{{ luaran.nama }}</td>
                    <td>
                      <ol>
                        <li
                          v-for="(kategori, j) in luaran.data_kategori"
                          :key="j"
                        >
                          {{ kategori.nama }}
                        </li>
                      </ol>
                    </td>
                    <td>
                      <button
                        class="btn btn-sm btn-secondary"
                        data-bs-toggle="modal"
                        data-bs-target="#modalFormLuaranTambahanKategori"
                        @click="modalKategori(luaran.id)"
                      >
                        Kategori
                      </button>
                      <button
                        class="btn btn-sm btn-danger ms-2"
                        @click="deleteLuaranTambahan(luaran.id)"
                      >
                        Hapus
                      </button>
                    </td>
                  </tr>
                </template>
              </table-basic>
            </div>
          </div>
        </template>
      </card-basic>
    </template>
  </container-app>

  <!-- begin::modal -->
  <!-- begin::profilDosen -->
  <modal-medium id="profilDosen" title="Profil Dosen">
    <template #content>
      <div class="d-flex mb-4" v-if="dosen.penelitian == 0">
        <div class="alert alert-danger m-0 me-3 flex-fill">
          Belum Memiliki Akses Penelitian
        </div>
        <button
          class="btn btn-secondary"
          @click="bukaAksesPenelitian(dosen.id)"
        >
          Buka Akses
        </button>
      </div>

      <div class="d-flex mb-4" v-else-if="dosen.penelitian == 1">
        <div class="alert alert-success m-0 me-3 flex-fill">
          Memiliki Akses Penelitian
        </div>
        <button
          class="btn btn-secondary"
          @click="tutupAksesPenelitian(dosen.id)"
        >
          Tutup Akses
        </button>
      </div>

      <div class="card border mb-5">
        <div class="card-body p-5">
          <label-basic name="Profil Dosen" />

          <div class="mb-2 mt-3 row">
            <span class="fs-8 text-gray-700 d-block col-sm-3">NIDN</span>
            <span class="fs-7 fw-bold text-gray-800 col-sm-9">{{
              dosen.nidn
            }}</span>
          </div>
          <div class="mb-2 row">
            <span class="fs-8 text-gray-700 d-block col-sm-3"
              >Nama Lengkap</span
            >
            <span class="fs-7 fw-bold text-gray-800 col-sm-9">{{
              dosen.nama
            }}</span>
          </div>
          <div class="mb-2 row">
            <span class="fs-8 text-gray-700 d-block col-sm-3"
              >Jenis Kelamin</span
            >
            <span class="fs-7 fw-bold text-gray-800 col-sm-9">{{
              dosen.jenis_kelamin
            }}</span>
          </div>
          <div class="mb-2 row">
            <span class="fs-8 text-gray-700 d-block col-sm-3"
              >Tempat, Tanggal Lahir</span
            >
            <span class="fs-7 fw-bold text-gray-800 col-sm-9"
              >{{ dosen.tempat_lahir }}, {{ dosen.tanggal_lahir }}</span
            >
          </div>
          <div class="mb-2 mt-3 row">
            <span class="fs-8 text-gray-700 d-block col-sm-3"
              >Program Studi</span
            >
            <span class="fs-7 fw-bold text-gray-800 col-sm-9">{{
              dosen.program_studi
            }}</span>
          </div>
        </div>
      </div>

      <div class="card border mb-5">
        <div class="card-body p-5">
          <label-basic name="Publikasi" />

          <div
            class="mb-2 mt-3 row"
            v-for="(penelitian_dosen, i) in data_penelitian_dosen"
            :key="i"
          >
            <span class="fs-8 text-gray-700 d-block col-sm-3">{{
              penelitian_dosen.nama
            }}</span>
            <span class="fs-7 fw-bold text-gray-800 col-sm-9">{{
              penelitian_dosen.url
            }}</span>
          </div>
        </div>
      </div>

      <div class="card border mb-5">
        <div class="card-body p-5">
          <label-basic name="Kontak" />

          <div class="mb-2 mt-3 row">
            <span class="fs-8 text-gray-700 d-block col-sm-3">No. HP</span>
            <span class="fs-7 fw-bold text-gray-800 col-sm-9">{{
              dosen.no_hp
            }}</span>
          </div>
          <div class="mb-2 row">
            <span class="fs-8 text-gray-700 d-block col-sm-3">Email</span>
            <span class="fs-7 fw-bold text-gray-800 col-sm-9">{{
              dosen.email
            }}</span>
          </div>
        </div>
      </div>

      <div class="card border mb-5">
        <div class="card-body p-5">
          <label-basic name="Pendidikan" />

          <div class="mb-2 mt-3 row">
            <span class="fs-8 text-gray-700 d-block col-sm-3"
              >Pendidikan Terakhir</span
            >
            <span class="fs-7 fw-bold text-gray-800 col-sm-9">{{
              dosen.pendidikan_terakhir
            }}</span>
          </div>
          <div class="mb-2 row">
            <span class="fs-8 text-gray-700 d-block col-sm-3">Gelar Depan</span>
            <span class="fs-7 fw-bold text-gray-800 col-sm-9">{{
              dosen.gelar_depan
            }}</span>
          </div>
          <div class="mb-2 row">
            <span class="fs-8 text-gray-700 d-block col-sm-3"
              >Gelar Belakang</span
            >
            <span class="fs-7 fw-bold text-gray-800 col-sm-9">{{
              dosen.gelar_belakang
            }}</span>
          </div>
        </div>
      </div>

      <div class="card border mb-5">
        <div class="card-body p-5">
          <label-basic name="Kualifikasi" />

          <div class="mb-2 row">
            <span class="fs-8 text-gray-700 d-block col-sm-3"
              >Kelompok Keahlian</span
            >
            <span class="fs-7 fw-bold text-gray-800 col-sm-9">{{
              dosen.kelompok_keahlian
            }}</span>
          </div>
          <div class="mb-2 row">
            <span class="fs-8 text-gray-700 d-block col-sm-3"
              >Jabatan Akademik</span
            >
            <span class="fs-7 fw-bold text-gray-800 col-sm-9">{{
              dosen.jabatan_akademik
            }}</span>
          </div>
          <div class="mb-2 row">
            <span class="fs-8 text-gray-700 d-block col-sm-3"
              >Pangkat Golongan</span
            >
            <span class="fs-7 fw-bold text-gray-800 col-sm-9">{{
              dosen.pangkat_golongan
            }}</span>
          </div>
        </div>
      </div>
    </template>
  </modal-medium>
  <!-- end::profilDosen -->

  <!-- begin::editDosen -->
  <modal-fullscreen-sm id="editDosen" title="Edit Dosen">
    <template #content>
      <form class="p-1" @submit.prevent="updateProdiKK">
        <div class="form-group mb-3">
          <label class="col-form-label" for="dosen_nama">Nama Dosen</label>
          <input
            type="text"
            name="nama"
            id="dosen_nama"
            class="form-control bg-secondary"
            :value="dosen.nama"
            readonly
          />
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label" for="dosen_program_studi"
            >Program Studi</label
          >
          <select
            class="form-select"
            required
            v-model="form_dosen.dm_program_studi_id"
          >
            <option
              v-for="(program_studi, i) in data_program_studi"
              :key="i"
              :value="program_studi.id"
            >
              {{ program_studi.nama }}
            </option>
          </select>
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label" for="dosen_kelompok_keahlian"
            >Kelompok Keahlian</label
          >
          <select
            class="form-select"
            required
            v-model="form_dosen.dm_kelompok_keahlian_id"
          >
            <option
              v-for="(kelompok_keahlian, i) in data_kelompok_keahlian"
              :key="i"
              :value="kelompok_keahlian.id"
            >
              {{ kelompok_keahlian.nama }}
            </option>
          </select>
        </div>

        <input type="submit" value="Simpan" class="btn btn-success mt-5" />
      </form>
    </template>
  </modal-fullscreen-sm>
  <!-- end::editDosen -->

  <!-- begin::modalJadwalPenelitian -->
  <modal-fullscreen-sm id="modalJadwalPenelitian" title="Jadwal Penelitian">
    <template #content>
      <form class="p-1" @submit.prevent="storeJadwalPengusulan">
        <div class="form-group mb-3">
          <label class="col-form-label" for="tahun_usulan">Tahun Usulan</label>
          <input
            type="text"
            name="tahun_usulan"
            id="tahun_usulan"
            class="form-control"
            v-model="form_jadwal.tahun_usulan"
            required
          />
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label" for="semester">Semester</label>
          <input
            type="text"
            name="semester"
            id="semester"
            class="form-control"
            v-model="form_jadwal.semester"
            required
          />
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label" for="jumlah_penelitian"
            >Jumlah Penelitian</label
          >
          <input
            type="number"
            name="jumlah_penelitian"
            id="jumlah_penelitian"
            class="form-control"
            v-model="form_jadwal.jumlah_penelitian"
            required
          />
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label" for="jumlah_pkm">Jumlah PKM</label>
          <input
            type="number"
            name="jumlah_pkm"
            id="jumlah_pkm"
            class="form-control"
            v-model="form_jadwal.jumlah_pkm"
            required
          />
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label" for="jumlah_anggota"
            >Jumlah Anggota</label
          >
          <input
            type="number"
            name="jumlah_anggota"
            id="jumlah_anggota"
            class="form-control"
            v-model="form_jadwal.jumlah_anggota"
            required
          />
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label" for="jumlah_reviewer"
            >Jumlah Reviewer</label
          >
          <input
            type="number"
            name="jumlah_reviewer"
            id="jumlah_reviewer"
            class="form-control"
            v-model="form_jadwal.jumlah_reviewer"
            required
          />
        </div>

        <input type="submit" value="Simpan" class="btn btn-success mt-5" />
      </form>
    </template>
  </modal-fullscreen-sm>
  <!-- end::modalJadwalPenelitian -->

  <!-- begin::modalTargetIuran -->
  <modal-fullscreen-sm id="modalTargetIuran" title="Target Iuran">
    <template #content>
      <form @submit.prevent="storeSkemaIuran">
        <div class="form-group mb-3">
          <label class="col-form-label" for="target_iuran">Target Luaran</label>
          <input
            type="text"
            name="target_iuran"
            id="target_iuran"
            class="form-control"
            v-model="form_skema_iuran.target_iuran"
            required
          />
        </div>
        <div class="form-group mb-3">
          <label class="col-form-label" for="biaya">Biaya</label>
          <input
            type="text"
            name="biaya"
            id="biaya"
            class="form-control"
            v-model="form_skema_iuran.biaya"
            required
          />
        </div>
        <div class="form-group mb-3">
          <label class="col-form-label">Dana Awal (%)</label>
          <input
            type="number"
            class="form-control"
            v-model="form_skema_iuran.dana_awal"
            required
          />
        </div>

        <input type="submit" value="Simpan" class="btn btn-success mt-5" />
      </form>
    </template>
  </modal-fullscreen-sm>
  <!-- end::modalTargetIuran -->

  <!-- begin::modalReviewer -->
  <modal-fullscreen-sm id="modalReviewer" title="Form Reviewer">
    <template #content>
      <form class="p-1" @submit.prevent="storeReviewer">
        <div class="form-group mb-3">
          <label class="col-form-label" for="dm_dosen_id">Dosen</label>
          <select
            class="form-select"
            id="dm_dosen_id"
            name="dm_dosen_id"
            v-model="form_reviewer.dm_dosen_id"
          >
            <option v-for="(dosen, i) in data_dosen" :key="i" :value="dosen.id">
              {{ dosen.id }} - {{ dosen.nama }}
            </option>
          </select>
        </div>

        <input type="submit" value="Tambah" class="btn btn-success mt-5" />
      </form>
    </template>
  </modal-fullscreen-sm>
  <!-- end::modalReviewer -->

  <!-- begin::modalFormPenilaian  -->
  <modal-fullscreen-sm id="modalFormPenilaian" title="Form Penilaian">
    <template #content>
      <form class="p-1" @submit.prevent="storeFormPenilaian">
        <div class="form-group mb-3">
          <label class="col-form-label" for="nama">Nama Soal</label>
          <input
            type="text"
            name="nama"
            id="nama"
            class="form-control"
            v-model="form_form_penilaian.nama"
            required
          />
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label" for="keterangan">Keterangan</label>
          <textarea
            name="keterangan"
            id="keterangan"
            class="form-control"
            v-model="form_form_penilaian.keterangan"
            rows="7"
            required
          >
          </textarea>
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label" for="bobot">Bobot</label>
          <input
            type="text"
            name="bobot"
            id="bobot"
            class="form-control"
            v-model="form_form_penilaian.bobot"
            required
          />
        </div>

        <input type="submit" value="Simpan" class="btn btn-success mt-5" />
      </form>
    </template>
  </modal-fullscreen-sm>
  <!-- end::modalFormPenilaian  -->

  <!-- begin::detailUsulanPPM -->
  <modal-medium id="detailUsulanPPM" title="Detail Usulan Penelitian dan PkM">
    <template #content>
      <div class="card border border-secondary">
        <div class="card-basic p-4">
          <label-basic name="Profil Dosen" />

          <div class="row mb-2 mt-3">
            <div class="col-sm-3">Nama Dosen</div>
            <div class="col-sm-9 fw-bold d-block">
              : {{ pengusul.dosen_nama }}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-3">NIDN</div>
            <div class="col-sm-9 fw-bold d-block">
              : {{ pengusul.dosen_nidn }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">Program Studi</div>
            <div class="col-sm-9 fw-bold d-block">
              : {{ pengusul.program_studi }}
            </div>
          </div>
        </div>
      </div>

      <nav class="mt-4">
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            class="nav-link"
            :class="i == 1 ? 'active' : ''"
            id="nav-detail_usulan_ppm_usulan_1-tab"
            data-bs-toggle="tab"
            type="button"
            role="tab"
            aria-selected="true"
            v-for="i in jumlah_usulan"
            :key="i"
            @click="detailUsulan(index_usulan, i - 1)"
          >
            Usulan {{ i }}
          </button>
        </div>
      </nav>

      <div class="card border border-secondary">
        <div class="card-basic p-4">
          <div class="d-flex justify-content-between mb-4">
            <label-basic name="Usulan PPM" />
            <span
              class="btn btn-sm btn-link p-0"
              v-if="!detail[0]"
              @click="detail[0] = !detail[0]"
              >Detail <icon-arrow-bottom
            /></span>
            <span
              class="btn btn-sm btn-link text-danger p-0"
              v-else
              @click="detail[0] = !detail[0]"
              >Tutup Detail <icon-arrow-up
            /></span>
          </div>

          <div v-if="!detail[0]">
            <p>{{ pengusul.judul }}</p>
            <div class="d-flex justify-content-between">
              <div>
                <span class="badge badge-info">{{ pengusul.jenis }}</span>
                <span
                  class="badge ms-2"
                  :class="
                    ['DISETUJUI', 'PERUBAHAN DISETUJUI'].includes(
                      pengusul.status
                    )
                      ? 'badge-success'
                      : 'badge-secondary'
                  "
                  >{{ pengusul.status }}</span
                >
              </div>
              <a
                class="btn btn-sm btn-link p-0"
                :href="pengusul.link_file_proposal"
                v-if="pengusul.file_proposal != null"
                target="_blank"
                >Lihat File Proposal</a
              >
              <span v-else class="text-danger">Tidak Ada File Proposal</span>
            </div>

            <div v-if="pengusul.nonaktif != null" class="text-center mt-3">
              Dinonaktifkan pada tanggal : {{ pengusul.nonaktif }}
            </div>
          </div>

          <div v-else>
            <div class="row mb-2">
              <div class="col-sm-3">Jenis</div>
              <div class="col-sm-9 fw-bold d-block">: {{ pengusul.jenis }}</div>
            </div>
            <div class="row mb-2" v-if="pengusul.jenis == 'LUARAN TAMBAHAN'">
              <div class="col-sm-3">Luaran</div>
              <div class="col-sm-9 fw-bold d-block">
                : {{ pengusul.luaran_tambahan }}
              </div>
            </div>
            <div class="row mb-2" v-if="pengusul.jenis == 'LUARAN TAMBAHAN'">
              <div class="col-sm-3">Kategori</div>
              <div class="col-sm-9 fw-bold d-block">
                : {{ pengusul.luaran_tambahan_kategori }}
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-3">Tema</div>
              <div class="col-sm-9 fw-bold d-block">: {{ pengusul.tema }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-3">Judul</div>
              <div class="col-sm-9 fw-bold d-block">: {{ pengusul.judul }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-3">Latar Belakang</div>
              <div class="col-sm-9 fw-bold d-block">
                : {{ pengusul.latar_belakang }}
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-3">Tujuan</div>
              <div class="col-sm-9 fw-bold d-block">
                : {{ pengusul.tujuan }}
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-3">Metode</div>
              <div class="col-sm-9 fw-bold d-block">
                : {{ pengusul.metode }}
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-3">Kebaruan</div>
              <div class="col-sm-9 fw-bold d-block">
                : {{ pengusul.kebaruan }}
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-sm-3">Anggota</div>
              <div class="col-sm-9 fw-bold">
                <span
                  class="d-block"
                  v-for="(anggota, index) in data_anggota"
                  :key="index"
                  >: {{ anggota.anggota_nama }}</span
                >
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-3">Status</div>
              <div class="col-sm-9 fw-bold d-block">
                :
                <span
                  class="badge"
                  :class="
                    ['DISETUJUI', 'PERUBAHAN DISETUJUI'].includes(
                      pengusul.status
                    )
                      ? 'badge-success'
                      : 'badge-secondary'
                  "
                  >{{ pengusul.status }}</span
                >
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-3">File Proposal</div>
              <div class="col-sm">
                :
                <a
                  class="btn btn-sm btn-link p-0"
                  :href="pengusul.link_file_proposal"
                  target="_blank"
                  >Lihat File Proposal</a
                >
              </div>
            </div>

            <div class="row mt-7">
              <div class="col-sm-3"></div>
              <div class="col-sm-9">
                <div
                  v-if="
                    (lembaga_jabatan == 'PROGRAM STUDI') &
                    (pengusul.validasi_program_studi == 0)
                  "
                >
                  <button
                    class="btn btn-sm btn-success"
                    @click="validasiProgramStudi(pengusul.id)"
                  >
                    Validasi
                  </button>
                  <button
                    class="btn btn-sm btn-danger ms-2"
                    @click="tolakPengusulan(pengusul.id)"
                  >
                    Tolak
                  </button>
                </div>
                <div
                  v-if="
                    (lembaga_jabatan == 'LPPM STAF') &
                    (pengusul.validasi_staf_lppm == 0) &
                    (pengusul.validasi_program_studi == 1)
                  "
                >
                  <button
                    class="btn btn-sm btn-success"
                    @click="validasiStafLPPM(pengusul.id)"
                  >
                    Validasi
                  </button>
                  <button
                    class="btn btn-sm btn-danger ms-2"
                    @click="tolakPengusulan(pengusul.id)"
                  >
                    Tolak
                  </button>
                </div>
                <div
                  v-if="
                    (lembaga_jabatan == 'LPPM KETUA') &
                    (pengusul.validasi_lppm == 0) &
                    (pengusul.validasi_staf_lppm == 1)
                  "
                >
                  <button
                    class="btn btn-sm btn-success"
                    @click="validasiLPPM(pengusul.id)"
                  >
                    Validasi
                  </button>
                  <button
                    class="btn btn-sm btn-danger ms-2"
                    @click="tolakPengusulan(pengusul.id)"
                  >
                    Tolak
                  </button>
                </div>

                <div
                  class="mb-2"
                  v-if="
                    (lembaga_jabatan == 'LPPM KETUA' ||
                      lembaga_jabatan == 'LPPM STAF') &&
                    pengusul != {}
                  "
                >
                  <button
                    v-if="pengusul.nonaktif == null"
                    class="btn btn-sm btn-danger"
                    @click="postNonaktif(pengusul.id)"
                  >
                    Nonaktifkan Usulan
                  </button>
                </div>

                <div
                  v-if="
                    (lembaga_jabatan == 'LPPM KETUA') &
                    (pengusul.edit_status == 1)
                  "
                >
                  <button
                    class="btn btn-sm btn-success"
                    @click="validasiPerubahan(pengusul.id, 'VALIDASI')"
                  >
                    Validasi Pengajuan Perubahan
                  </button>
                  <button
                    class="btn btn-sm btn-danger ms-2"
                    @click="validasiPerubahan(pengusul.id, 'TOLAK')"
                  >
                    Tolak Pengajuan Perubahan
                  </button>
                </div>

                <div
                  class="mt-2 d-flex gap-2"
                  v-if="validator_perubahan == lembaga_jabatan"
                >
                  <button
                    class="btn btn-sm btn-success"
                    @click="validasiPerubahan2(pengusul.id, 'VALIDASI')"
                  >
                    Validasi Perubahan
                  </button>
                  <button
                    class="btn btn-sm btn-danger"
                    @click="validasiPerubahan2(pengusul.id, 'TOLAK')"
                  >
                    Tolak Perubahan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="card border border-secondary mt-4"
        v-if="
          (lembaga_jabatan != 'PROGRAM STUDI') &
          (lembaga_jabatan != 'ITG REKTOR')
        "
      >
        <div class="card-basic p-4">
          <div class="d-flex justify-content-between mb-4">
            <label-basic name="REVIEWER" />
            <span
              class="btn btn-sm btn-link p-0"
              v-if="!detail[1]"
              @click="detail[1] = !detail[1]"
              >Detail <icon-arrow-bottom
            /></span>
            <span
              class="btn btn-sm btn-link text-danger p-0"
              v-else
              @click="detail[1] = !detail[1]"
              >Tutup Detail <icon-arrow-up
            /></span>
          </div>

          <div v-if="!detail[1]">
            <div
              class="row mb-2"
              v-for="(data_reviewer, index) in data_pengusulan_reviewer"
              :key="index"
            >
              <div class="col-sm-3">Reviewer {{ index + 1 }}</div>
              <div class="col-sm-9 fw-bold d-block">
                : {{ data_reviewer.reviewer_nama }}
              </div>
            </div>
          </div>

          <div v-else>
            <form
              class="d-flex"
              @submit.prevent="storePengusulanReviewer"
              v-if="pengusul.validasi_lppm == 1"
            >
              <select
                id="dm_reviewer_id"
                name="dm_reviewer_id"
                class="form-select form-select-sm me-3"
                v-model="form_pengusulan_reviewer.dm_reviewer_id"
              >
                <option
                  v-for="(reviewer, i) in data_reviewer"
                  :key="i"
                  :value="reviewer.id"
                >
                  {{ reviewer.dosen_nama }}
                </option>
              </select>
              <input
                type="submit"
                value="Tambah"
                class="btn btn-sm btn-primary"
              />
            </form>

            <table-basic class="mt-4">
              <template #thead>
                <tr class="fw-bolder bg-light fs-7">
                  <th class="rounded-start ps-5 w-md-50px">No.</th>
                  <th>Reviewer</th>
                  <th>Aksi</th>
                </tr>
              </template>

              <template #tbody>
                <tr
                  v-for="(pengusulan_reviewer, i) in data_pengusulan_reviewer"
                  :key="i"
                >
                  <th class="ps-5 text-end">{{ i + 1 }}</th>
                  <th>{{ pengusulan_reviewer.reviewer_nama }}</th>
                  <th>
                    <button
                      class="btn btn-sm btn-danger"
                      v-if="pengusulan_reviewer.skor == 0"
                      @click="deletePengusulanReviewer(pengusulan_reviewer.id)"
                    >
                      Hapus
                    </button>
                  </th>
                </tr>
              </template>
            </table-basic>
          </div>
        </div>
      </div>

      <div
        class="card border border-secondary mt-4"
        v-if="lembaga_jabatan != 'PROGRAM STUDI'"
      >
        <div class="card-basic p-4">
          <div class="d-flex justify-content-between mb-4">
            <label-basic name="PENILAIAN" />
            <span
              class="btn btn-sm btn-link p-0"
              v-if="!detail[2]"
              @click="detail[2] = !detail[2]"
              >Detail <icon-arrow-bottom
            /></span>
            <span
              class="btn btn-sm btn-link text-danger p-0"
              v-else
              @click="detail[2] = !detail[2]"
              >Tutup Detail <icon-arrow-up
            /></span>
          </div>

          <div v-if="!detail[2]">
            <div
              class="row mb-2"
              v-for="(nilai, index) in data_nilai"
              :key="index"
            >
              <div class="col-sm-3">Reviewer {{ index + 1 }}</div>
              <div class="col-sm-9 fw-bold d-block">
                : {{ nilai.skor }} ({{ nilai.keterangan }})
                <span class="badge badge-danger" v-if="nilai.kunci == 0"
                  >Unreviewed</span
                >
                <span class="badge badge-success" v-else>Reviewed</span>
              </div>
            </div>
          </div>

          <div v-else>
            <div v-for="(nilai, index) in data_nilai" :key="index">
              <div class="row mb-2">
                <div class="col-sm-3">Reviewer {{ index + 1 }}</div>
                <div
                  class="col-sm-9 fw-bold d-block d-flex justify-content-between"
                >
                  <div>
                    : {{ nilai.nama_dosen }}
                    <span class="badge badge-danger" v-if="nilai.kunci == 0"
                      >Unreviewed</span
                    >
                    <span class="badge badge-success" v-else>Reviewed</span>
                  </div>
                  <a
                    :href="nilai.link_form_penilaian"
                    class="btn btn-sm btn-danger mb-2"
                    v-if="(nilai.kunci == 1) & (pengusul.validasi_rektor == 1)"
                    >Download Hasil Penilaian</a
                  >
                </div>
              </div>

              <table-basic>
                <template #thead>
                  <tr class="fw-bolder bg-light fs-7">
                    <th class="rounded-start ps-5 w-50px">No.</th>
                    <th>Penilaian</th>
                    <th class="w-100px">Bobot (%)</th>
                    <th class="rounded-end w-100px">Skor</th>
                  </tr>
                </template>

                <template #tbody>
                  <tr v-for="(rincian, i) in nilai.data_rincian" :key="i">
                    <th class="ps-5 text-end">{{ i + 1 }}</th>
                    <td>
                      <span class="fw-bold d-block">{{ rincian.nama }}</span>
                      <span v-html="rincian.keterangan"></span>
                    </td>
                    <td>{{ rincian.bobot }}</td>
                    <td>{{ rincian.skor }}</td>
                  </tr>

                  <tr class="bg-secondary">
                    <td colspan="4" class="text-center rounded">
                      Total = <span class="fw-bolder">{{ nilai.skor }}</span> (
                      <span class="fw-bolder">{{ nilai.keterangan }}</span> )
                    </td>
                  </tr>
                </template>
              </table-basic>

              <label class="col-form-label pb-0">Catatan :</label>
              <p class="mt-0">{{ nilai.catatan }}</p>

              <div class="mb-10" v-if="index < data_nilai.length - 1" />
            </div>

            <div
              class="d-flex justify-content-end mb-2"
              v-if="
                (lembaga_jabatan == 'ITG REKTOR') &
                (pengusul.validasi_rektor == 0) &
                (belum_dikunci == 0) &
                (data_nilai.length > 0)
              "
            >
              <button
                class="btn btn-sm btn-success"
                @click="validasiRektor(pengusul.id)"
              >
                Validasi
              </button>
              <button
                class="btn btn-sm btn-danger ms-2"
                @click="tolakPengusulan(pengusul.id)"
              >
                Tolak
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="card border border-secondary mt-4"
        v-if="
          (lembaga_jabatan != 'PROGRAM STUDI') &
          (lembaga_jabatan != 'ITG REKTOR')
        "
      >
        <div class="card-basic p-4">
          <div class="d-flex justify-content-between mb-4">
            <label-basic name="SURAT PERJANJIAN" />
            <span
              class="btn btn-sm btn-link p-0"
              v-if="!detail[3]"
              @click="detail[3] = !detail[3]"
              >Detail <icon-arrow-bottom
            /></span>
            <span
              class="btn btn-sm btn-link text-danger p-0"
              v-else
              @click="detail[3] = !detail[3]"
              >Tutup Detail <icon-arrow-up
            /></span>
          </div>

          <div v-if="!detail[3]">
            <div class="row mb-2">
              <div class="col-sm-3">No. Surat</div>
              <div class="col-sm-9 fw-bold d-block">
                : {{ form_surat_perjanjian.no_surat_perjanjian }}
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-3">Tanggal</div>
              <div class="col-sm-9 fw-bold d-block">
                : {{ form_surat_perjanjian.tanggal_surat_perjanjian }}
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-3">Surat Perjanjian</div>
              <div class="col-sm-9 fw-bold d-block">
                :
                <a
                  :href="pengusul.file_surat_perjanjian"
                  target="_blank"
                  v-if="pengusul.file_surat_perjanjian != '-'"
                  class="btn btn-sm btn-link p-0"
                  >Lihat</a
                >
              </div>
            </div>
          </div>

          <div v-else>
            <form @submit.prevent="suratPerjanjianPengusulan">
              <div class="form-group row mb-2">
                <label class="col-form-label col-sm-3">No. Surat</label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    required
                    v-model="form_surat_perjanjian.no_surat_perjanjian"
                  />
                </div>
              </div>
              <div class="form-group row mb-2">
                <label class="col-form-label col-sm-3">Tanggal</label>
                <div class="col-sm-9">
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    required
                    v-model="form_surat_perjanjian.tanggal_surat_perjanjian"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-9">
                  <input
                    type="submit"
                    value="Simpan"
                    class="btn btn-sm btn-success me-3"
                  />
                </div>
              </div>
            </form>

            <form class="mt-8" @submit.prevent="storeSuratPerjanjian">
              <div class="form-group row mb-3">
                <label class="col-form-label col-sm-3"
                  >Upload Surat Perjanjian</label
                >
                <div class="col-sm-9">
                  <input
                    type="file"
                    id="fileSuratPerjanjian"
                    class="form-control form-control-sm"
                    required
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-9">
                  <input
                    type="submit"
                    value="Upload"
                    class="btn btn-sm btn-success me-3"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
  </modal-medium>
  <!-- end::detailUsulanPPM -->
  <!-- end::modal -->

  <!-- begin::modalFormLuaranTambahan -->
  <modal-fullscreen-sm id="modalFormLuaranTambahan" title="Luaran Tambahan">
    <template #content>
      <form @submit.prevent="storeLuaranTambahan">
        <div class="form-group mb-3">
          <label class="col-form-label" for="luaran_tambahan_nama"
            >Luaran Tambahan</label
          >
          <input
            type="text"
            name="luaran_tambahan_nama"
            id="luaran_tambahan_nama"
            class="form-control"
            v-model="form_luaran_tambahan.nama"
            required
          />
        </div>

        <input type="submit" value="Simpan" class="btn btn-success mt-5" />
      </form>
    </template>
  </modal-fullscreen-sm>
  <!-- end::modalFormLuaranTambahan -->

  <!-- begin::modalFormLuaranTambahanKategori -->
  <modal-fullscreen-sm
    id="modalFormLuaranTambahanKategori"
    title="Luaran Tambahan"
  >
    <template #content>
      <form @submit.prevent="storeLuaranTambahanKategori">
        <div class="form-group mb-3">
          <label class="col-form-label">Luaran Tambahan</label>
          <p>{{ luaran_tambahan.nama }}</p>
        </div>
        <div class="form-group mb-3">
          <label class="col-form-label" for="luaran_tambahan_kategori_nama"
            >Kategori</label
          >
          <input
            type="text"
            name="luaran_tambahan_kategori_nama"
            id="luaran_tambahan_kategori_nama"
            class="form-control"
            v-model="form_luaran_tambahan_kategori.nama"
            required
          />
        </div>

        <input type="submit" value="Tambah" class="btn btn-success mt-5" />
      </form>
    </template>
  </modal-fullscreen-sm>
  <!-- end::modalFormLuaranTambahan -->
</template>

<script>
import {
  indexDosen,
  showDosen,
  updateProdiKK,
  bukaAksesPenelitian,
  tutupAksesPenelitian,
} from "@/apis/dosen2";
import { dataKelompokKeahlian } from "@/apis/kelompokKeahlian";
import { indexProgramStudi } from "@/apis/programStudi";
import {
  indexJadwalPengusulan,
  storeJadwalPengusulan,
  ubahJadwalPengusulan,
} from "@/apis/jadwalPengusulan";
import {
  indexSkemaIuran,
  storeSkemaIuran,
  deleteSkemaIuran,
} from "@/apis/skemaIuran";
import {
  indexPengusulan,
  showPengusulan,
  validasiPengusulan,
  penilaianPengusulan,
  suratPerjanjianPengusulan,
  uploadFileSuratPerjanjian,
  validasiPerubahan,
  validasiPerubahan2,
  tolakPengusulan,
  postNonaktif,
} from "@/apis/pengusulan";
import { dataPengusulanAnggota } from "@/apis/pengusulanAnggota";
import { indexReviewer, storeReviewer, deleteReviewer } from "@/apis/reviewer";
import {
  dataPengusulanReviewer,
  storePengusulanReviewer,
  deletePengusulanReviewer,
} from "@/apis/pengusulanReviewer";
import { indexFormPenilaian, storeFormPenilaian } from "@/apis/formPenilaian";
import { dataPenelitianDosen } from "@/apis/penelitianDosen";
import { lembagaJabatan } from "@/apis/user";
import {
  storeLuaranTambahan,
  dataLuaranTambahan,
  deleteLuaranTambahan,
  showLuaranTambahan,
} from "@/apis/luaranTambahan";
import { indexLuaranTambahanKategori } from "@/apis/luaranTambahanKategori";
import { downloadPengusulan } from "@/apis/download";

// store
import { getUserData } from "@/store";

export default {
  beforeMount() {
    localStorage.setItem("menu", "Penelitian dan PkM");
    localStorage.setItem("halaman", "Validasi PPM");
    this.lembagaJabatan();
  },
  data() {
    return {
      user: getUserData(),
      data_dosen: {},
      form_dosen: {},
      dosen: {},
      data_program_studi: {},
      data_kelompok_keahlian: {},
      form_jadwal: {},
      data_jadwal_pengusulan: {},
      form_skema_iuran: {},
      data_skema_iuran: {},
      data_pengusul: {},
      pengusul: {},
      data_anggota: {},
      data_reviewer: {},
      form_reviewer: {},
      data_pengusulan_reviewer: {},
      form_pengusulan_reviewer: {},
      data_form_penilaian: {},
      form_form_penilaian: {},
      lembaga_jabatan: "-",
      data_nilai: {},
      form_surat_perjanjian: {},
      data_penelitian_dosen: {},
      usulan_ppm: null,
      detail: [false, false, false, false],
      jumlah_usulan: 0,
      index_usulan: 0,
      full_akses: false,
      jadwal_id: 0,
      akses: ["LPPM KETUA", "LPPM STAF"],
      form_luaran_tambahan: {},
      data_luaran_tambahan: [],
      luaran_tambahan: {},
      form_luaran_tambahan_kategori: {},
      base_url: process.env.VUE_APP_BASE_URL,
      validator_perubahan: "-",
    };
  },
  mounted() {
    this.indexJadwalPengusulan();
    this.indexPengusulan();
    this.indexDosen();
    this.indexProgramStudi();
    this.dataKelompokKeahlian();
    this.indexSkemaIuran();
    this.indexReviewer();
    this.indexFormPenilaian();
    this.dataLuaranTambahan();
  },
  methods: {
    showTab(akses) {
      if (!this.user.akses.includes("ALL")) {
        return this.user.akses.includes(akses);
      } else {
        return true;
      }
    },
    async lembagaJabatan() {
      const res = await lembagaJabatan();

      this.lembaga_jabatan = res.data.lembaga_jabatan;

      if (this.user.akses.includes("ALL")) {
        this.full_akses = true;
      } else {
        this.full_akses = this.akses.includes(this.lembaga_jabatan)
          ? true
          : false;
      }
    },
    async indexPengusulan() {
      const res = await indexPengusulan(this.jadwal_id);

      this.data_pengusul = res.data.data.map((e) => {
        e.status_kode = e.ajukan == 0 ? 0 : 1;
        e.edit = e.ajukan == 0 ? true : false;
        e.hapus = e.ajukan == 0 ? true : false;

        return e;
      });

      this.jadwal_id = res.data.jadwal_id;
    },
    async indexDosen() {
      const res = await indexDosen();

      this.data_dosen = res.data.data;
    },
    async profilDosen(id) {
      this.dosen = {};

      const res = await showDosen(id);
      this.dosen = res.data.data;
      this.dataPenelitianDosen(id);
    },
    async modalEditDosen(id) {
      this.form_dosen = {};

      this.profilDosen(id);
      this.form_dosen = this.dosen;
      this.form_dosen.id_baru = id;
    },
    async indexProgramStudi() {
      const res = await indexProgramStudi();

      this.data_program_studi = res.data.data;
    },
    async indexJadwalPengusulan() {
      const res = await indexJadwalPengusulan();

      this.data_jadwal_pengusulan = res.data.data;
    },
    async dataKelompokKeahlian() {
      const res = await dataKelompokKeahlian();

      this.data_kelompok_keahlian = res.data.data;
    },
    async indexSkemaIuran() {
      const res = await indexSkemaIuran();

      this.data_skema_iuran = res.data.data;
    },
    async updateProdiKK() {
      this.form_dosen.id = this.dosen.id;

      try {
        const res = await updateProdiKK(this.form_dosen);

        if (res.status == 200) {
          this.indexDosen();

          Swal.fire({
            icon: "success",
            text: "Program Studi dan Kelompok Keahlian berhasil disimpan",
            showConfirmButton: false,
            timer: 5000,
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    async storeJadwalPengusulan() {
      try {
        const res = await storeJadwalPengusulan(this.form_jadwal);

        if (res.status == 200) {
          Swal.fire({
            icon: "success",
            text: "Jadwal Penelitian berhasil disimpan",
            showConfirmButton: false,
            timer: 5000,
          });

          this.form_jadwal = {};
          this.indexJadwalPengusulan();
        }
      } catch (err) {
        console.error(err);
      }
    },
    async storeSkemaIuran() {
      try {
        const res = await storeSkemaIuran(this.form_skema_iuran);

        if (res.status == 200) {
          Swal.fire({
            icon: "success",
            text: "Skema luaran berhasil disimpan",
            showConfirmButton: false,
            timer: 5000,
          });

          this.form_skema_iuran = {};

          this.indexSkemaIuran();
        }
      } catch (err) {
        console.error(err);
      }
    },
    async showPengusulan(id) {
      this.pengusul = {};
      this.validator_perubahan = "-";
      const res = await showPengusulan(id);

      this.pengusul = res.data.data;
      this.dataPengusulanAnggota(id);
      this.dataPengusulanReviewer(id);
      this.penilaianPengusulan(id);
      this.form_surat_perjanjian = this.pengusul;

      if (this.pengusul.edit_tanggal != null) {
        if (this.pengusul.validasi_perubahan_prodi == null) {
          this.validator_perubahan = "PROGRAM STUDI";
        } else if (this.pengusul.validasi_perubahan_staf_lppm == null) {
          this.validator_perubahan = "LPPM STAF";
        } else if (this.pengusul.validasi_perubahan_lppm == null) {
          this.validator_perubahan = "LPPM KETUA";
        }
      }
    },
    async dataPengusulanAnggota(tr_pengusulan_id) {
      const res = await dataPengusulanAnggota(tr_pengusulan_id);

      this.data_anggota = res.data.data;
    },
    async validasiPengusulan(data) {
      const res = await validasiPengusulan(data);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Usulan berhasil divalidasi",
          showConfirmButton: false,
          timer: 3000,
        });

        this.indexPengusulan();
        this.showPengusulan(data.id);
      }
    },
    async validasiProgramStudi(id) {
      this.validasiPengusulan({ id: id, validasi: "PROGRAM_STUDI" });
    },
    async validasiStafLPPM(id) {
      this.validasiPengusulan({ id: id, validasi: "STAF_LPPM" });
    },
    async validasiLPPM(id) {
      this.validasiPengusulan({ id: id, validasi: "LPPM" });
    },
    async validasiRektor(id) {
      this.validasiPengusulan({ id: id, validasi: "REKTOR" });
    },
    async indexReviewer() {
      const res = await indexReviewer();

      this.data_reviewer = res.data.data.sort((a, b) =>
        a.dosen_nama > b.dosen_nama ? 1 : b.dosen_nama > a.dosen_nama ? -1 : 0
      );
    },
    async storeReviewer() {
      const res = await storeReviewer(this.form_reviewer);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Reviewer berhasil ditambahkan",
          showConfirmButton: false,
          timer: 3000,
        });

        this.indexReviewer();
        this.form_reviewer = {};
      }
    },
    async dataPengusulanReviewer(id) {
      this.data_pengusulan_reviewer = {};
      const res = await dataPengusulanReviewer(id);

      this.data_pengusulan_reviewer = res.data.data;
    },
    async storePengusulanReviewer() {
      this.form_pengusulan_reviewer.tr_pengusulan_id = this.pengusul.id;
      const res = await storePengusulanReviewer(this.form_pengusulan_reviewer);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Reviewer berhasil ditambahkan",
          showConfirmButton: false,
          timer: 3000,
        });

        this.dataPengusulanReviewer(this.pengusul.id);
        this.form_pengusulan_reviewer = {};
      }
    },
    async deletePengusulanReviewer(id) {
      const res = await deletePengusulanReviewer(id);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Reviewer berhasil dihapus",
          showConfirmButton: false,
          timer: 3000,
        });

        this.dataPengusulanReviewer(this.pengusul.id);
      }
    },
    async indexFormPenilaian() {
      const res = await indexFormPenilaian();

      this.data_form_penilaian = res.data.data;
    },
    async storeFormPenilaian() {
      const res = await storeFormPenilaian(this.form_form_penilaian);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Form penilaian berhasil disimpan",
          showConfirmButton: false,
          timer: 3000,
        });

        this.form_form_penilaian = {};
        this.indexFormPenilaian();
      }
    },
    async bukaAksesPenelitian(id) {
      const res = await bukaAksesPenelitian(id);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Akses penelitian berhasil dibuka",
          showConfirmButton: false,
          timer: 3000,
        });

        this.indexDosen();
        this.profilDosen(id);
      }
    },
    async tutupAksesPenelitian(id) {
      const res = await tutupAksesPenelitian(id);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Akses penelitian berhasil ditutup",
          showConfirmButton: false,
          timer: 3000,
        });

        this.indexDosen();
        this.profilDosen(id);
      }
    },
    async penilaianPengusulan(id) {
      this.data_nilai = {};
      this.belum_dikunci = {};
      const res = await penilaianPengusulan(id);

      this.data_nilai = res.data.data;
      this.belum_dikunci = res.data.belum_dikunci;
    },
    async suratPerjanjianPengusulan() {
      this.form_surat_perjanjian.id = this.pengusul.id;

      const res = await suratPerjanjianPengusulan(this.form_surat_perjanjian);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "No Surat Perjanjian dan Tanggal berhasil disimpan",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    async dataPenelitianDosen(id) {
      const res = await dataPenelitianDosen(id);

      this.data_penelitian_dosen = res.data.data;
    },
    async storeSuratPerjanjian() {
      const file = document.querySelector("#fileSuratPerjanjian");

      let formData = new FormData();
      formData.append("id", this.pengusul.id);
      formData.append("file", file.files[0]);

      const res = await uploadFileSuratPerjanjian(formData);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "No Surat Perjanjian dan Tanggal berhasil disimpan",
          showConfirmButton: false,
          timer: 3000,
        });

        file.value = "";
        this.showPengusulan(this.pengusul.id);
      }
    },
    async deleteReviewer(id) {
      const res = await deleteReviewer(id);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Reviewer berhasil dihapus",
          showConfirmButton: false,
          timer: 3000,
        });

        this.indexReviewer();
      }
    },
    async deleteSkemaIuran(id) {
      const res = await deleteSkemaIuran(id);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Skema luaran berhasil dihapus",
          showConfirmButton: false,
          timer: 3000,
        });

        this.indexSkemaIuran();
      }
    },
    async showSkemaIuran(i) {
      this.form_skema_iuran = this.data_skema_iuran[i];
    },
    async showJadwalPenelitian(i) {
      this.form_jadwal = this.data_jadwal_pengusulan[i];
    },
    async showFormPenilaian(i) {
      this.form_form_penilaian = this.data_form_penilaian[i];
    },
    async validasiPerubahan(id, validasi) {
      const res = await validasiPerubahan({
        id: id,
        validasi: validasi,
      });

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Pengajuan perubahan berhasil divalidasi",
          showConfirmButton: false,
          timer: 3000,
        });

        this.indexPengusulan();
        this.showPengusulan(this.pengusul.id);
      }
    },

    async validasiPerubahan2(id, status) {
      const res = await validasiPerubahan2({
        id,
        status,
        validator: this.validator_perubahan,
      });

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Pengajuan perubahan berhasil divalidasi",
          showConfirmButton: false,
          timer: 3000,
        });

        this.indexPengusulan();
        this.showPengusulan(this.pengusul.id);
      }
    },
    async tolakPengusulan(id) {
      const res = await tolakPengusulan(id);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Pengusulan berhasil ditolak",
          showConfirmButton: false,
          timer: 3000,
        });

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    },
    detailUsulan(index, usulan = 0) {
      this.resetDetail();
      this.usulan_ppm = this.data_pengusul[index];
      this.index_usulan = index;
      this.jumlah_usulan = this.data_pengusul[index].jumlah_usulan;
      this.showPengusulan(this.usulan_ppm.data_usulan[usulan].id);
    },
    resetDetail() {
      this.detail.forEach((e, i) => {
        this.detail[i] = false;
      });
    },
    async ubahJadwalPengusulan(id) {
      const res = await ubahJadwalPengusulan(id);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Status berhasil diubah",
          showConfirmButton: false,
          timer: 3000,
        });

        this.indexJadwalPengusulan();
      }
    },
    formatRupiah(angka) {
      let reverse = angka.toString().split("").reverse().join("");
      let ribuan = reverse.match(/\d{1,3}/g);
      ribuan = ribuan.join(".").split("").reverse().join("");
      return "Rp " + ribuan + ",-";
    },
    async storeLuaranTambahan() {
      const res = await storeLuaranTambahan(this.form_luaran_tambahan);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Luaran Tambahan Berhasil Ditambahkan",
          showConfirmButton: false,
          timer: 3000,
        });
      }
      this.dataLuaranTambahan();
      this.form_luaran_tambahan = {};
    },
    async dataLuaranTambahan() {
      const res = await dataLuaranTambahan();

      this.data_luaran_tambahan = res.data.data;
    },
    async deleteLuaranTambahan(id) {
      const res = await deleteLuaranTambahan(id);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Luaran Tambahan Berhasil Dihapus",
          showConfirmButton: false,
          timer: 3000,
        });
      }
      this.dataLuaranTambahan();
    },
    async showLuaranTambahan(id) {
      const { data } = await showLuaranTambahan(id);

      this.luaran_tambahan = data.data;
    },
    async indexLuaranTambahanKategori(id) {
      const { data } = await indexLuaranTambahanKategori({
        luaran_tambahan_id: id,
      });

      console.log(data);
    },
    modalKategori(id) {
      this.showLuaranTambahan(id);
      this.indexLuaranTambahanKategori(id);
    },
    async postNonaktif(id) {
      const res = await postNonaktif(id);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: res.data.message,
          showConfirmButton: false,
          timer: 3000,
        });

        this.indexPengusulan();
        this.showPengusulan(id);
      }
    },
  },
};
</script>
