<template>
  <container-app>
    <template #content>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.7.2/css/all.min.css"
        integrity="sha512-Evv84Mr4kqVGRNSgIGL/F/aIDqQb7xQ2vcrdIwxfjThSH8CSR7PBEakCr51Ck+w+/U6swU2Im1vVX0SVk9ABhg=="
        crossorigin="anonymous"
        referrerpolicy="no-referrer"
      />

      <div class="mb-5">
        <select class="form-select w-auto" v-model="jadwal_id">
          <option
            v-for="(data, index) in data_jadwal_pengusulan"
            :key="index"
            :value="data.id"
          >
            {{ data.semester }}
          </option>
        </select>
      </div>

      <div class="row">
        <div class="col-md-2 col-6 mb-4">
          <card-widget
            label="Usulan PPM"
            :count="rekap.usulan_ppm"
            icon="fa-file-alt"
            color="text-dark"
            bgcolor="bg-light-dark"
          />
        </div>
        <div class="col-md-2 col-6 mb-4">
          <card-widget
            label="Proses Validasi"
            :count="rekap.proses_validasi"
            icon="fa-tasks"
            color="text-danger"
            bgcolor="bg-light-danger"
          />
        </div>
        <div class="col-md-2 col-6 mb-4">
          <card-widget
            label="Proses Reviewer"
            :count="rekap.proses_reviewer"
            icon="fa-eye"
            color="text-warning"
            bgcolor="bg-light-warning"
          />
        </div>
        <div class="col-md-2 col-6 mb-4">
          <card-widget
            label="Validasi Rektor"
            :count="rekap.validasi_rektor"
            icon="fa-user-check"
            color="text-primary"
            bgcolor="bg-light-primary"
          />
        </div>
        <div class="col-md-2 col-6 mb-4">
          <card-widget
            label="Pencairan Tahap 1"
            :count="rekap.pencairan_tahap_1"
            icon="fa-money-bill-wave"
            color="text-info"
            bgcolor="bg-light-info"
          />
        </div>
        <div class="col-md-2 col-6 mb-4">
          <card-widget
            label="Pencairan Tahap 2"
            :count="rekap.pencairan_tahap_2"
            icon="fa-check-circle"
            color="text-success"
            bgcolor="bg-light-success"
          />
        </div>
      </div>

      <card-basic>
        <template #content>
          <table-basic>
            <template #thead>
              <tr class="fw-bolder bg-light fs-7">
                <th class="rounded-start ps-5 w-md-50px">No.</th>
                <th class="w-md-300px">Dosen</th>
                <th>Penelitian</th>
              </tr>
            </template>

            <template #tbody>
              <tr v-for="(dosen, index) in rekap.data_dosen" :key="index">
                <td class="text-end">
                  {{ index + 1 }}
                </td>
                <td>
                  <b>{{ dosen.nama }}</b>
                  <br />{{ dosen.nidn }}
                </td>
                <td class="pe-3">
                  <div
                    class="mb-3"
                    v-for="(usulan, i) in dosen.data_usulan"
                    :key="i"
                    :class="{ 'border-top pt-2': i != 0 }"
                  >
                    <div class="d-flex justify-content-between">
                      <div>Penelitian {{ i + 1 }}</div>
                      <div>
                        <span
                          class="badge"
                          :class="{
                            'badge-light-danger': usulan.kode == 0,
                            'badge-light-warning': usulan.kode == 1,
                            'badge-light-primary': usulan.kode == 2,
                            'badge-light-info': usulan.kode == 3,
                            'badge-light-success': usulan.kode == 4,
                          }"
                        >
                          {{ usulan.status }}
                        </span>
                      </div>
                    </div>

                    <b>{{ usulan.judul }}</b>
                  </div>
                </td>
              </tr>
            </template>
          </table-basic>
        </template>
      </card-basic>
    </template>
  </container-app>
</template>

<script>
import { indexJadwalPengusulan } from "@/apis/jadwalPengusulan";
import { getRekap } from "@/apis/pengusulan";
export default {
  data() {
    return {
      data_jadwal_pengusulan: [],
      jadwal_id: null,
      rekap: {
        dosen_aktif: 0,
        usulan_ppm: 0,
        proses_validasi: 0,
        proses_reviewer: 0,
        validasi_rektor: 0,
        pencairan_tahap_1: 0,
        pencairan_tahap_2: 0,
      },
    };
  },

  mounted() {
    this.indexJadwalPengusulan();
  },

  watch: {
    jadwal_id() {
      this.getRekap();
    },
  },

  methods: {
    async indexJadwalPengusulan() {
      const res = await indexJadwalPengusulan();

      this.data_jadwal_pengusulan = res.data.data;
      this.jadwal_id = this.data_jadwal_pengusulan[0].id;
    },

    async getRekap() {
      const res = await getRekap(this.jadwal_id);

      this.rekap = res.data;
    },
  },
};
</script>
