<template>
  <div class="card">
    <div
      class="card-body border border-secondary rounded-2 px-6 py-5 shadow-sm"
    >
      <div class="symbol symbol-30px me-5 mb-8">
        <span class="symbol-label" :class="bgcolor">
          <i class="fa fa-1x" :class="[icon, color]"></i>
        </span>
      </div>

      <div class="m-0">
        <span class="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">
          {{ count }}
        </span>
        <span class="text-gray-500 fw-semibold fs-6">{{ label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["label", "count", "icon", "color", "bgcolor"],
};
</script>
