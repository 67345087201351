<template>
  <div class="table-responsive">
    <table class="table gs-0 gy-4 table-hover table-row-dashed">
      <thead>
        <slot name="thead"></slot>
      </thead>

      <tbody>
        <slot name="tbody"></slot>
      </tbody>
    </table>
  </div>
</template>
