<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <card-basic>
        <template #content>
          <div class="p-5">
            <label-header value="Monitoring Kontrak Perkuliahan">
              <template #control>
                <select
                  class="form-select form-select-solid"
                  data-control="selected_tahun_semester"
                  data-placeholder="Select an option"
                  v-model="selected_tahun_semester"
                  @change="onTahunSemesterChange($event)"
                >
                  <option
                    v-for="(item, i) in tahun_semester"
                    :key="i"
                    :value="item.thsms"
                  >
                    Tahun {{ item.ket }}
                  </option>
                </select>

                <select
                  class="ms-2 form-select form-select-solid"
                  data-control="prodi"
                  data-placeholder="Select an option"
                  v-model="prodi"
                  @change="onProdiChange($event)"
                >
                  <option
                    v-for="(item, i) in daftar_prodi"
                    :key="i"
                    :value="item.id"
                  >
                    {{ item.nama }}
                  </option>
                </select>
              </template>
            </label-header>
            <form-icon class="my-3">
              <template #icon>
                <icon-search />
              </template>

              <template #content>
                <input
                  type="text"
                  class="form-control ps-14 fw-normal"
                  placeholder="Masukkan Mata Kuliah atau Nama Dosen kemudian tekan enter"
                  autofocus
                  v-model="keyword"
                  @keyup.enter="
                    fetchDosenMataKuliah(
                      this.keyword,
                      this.prodi,
                      this.selected_tahun_semester
                    )
                  "
                />
              </template>
            </form-icon>
          </div>
          <table-basic>
            <template #thead>
              <tr class="fw-bolder bg-light fs-7">
                <th class="w-50px rounded-start text-center">No</th>
                <th class="min-w-50px">Mata Kuliah</th>
                <th class="min-w-50px">Kelas</th>
                <th class="min-w-100px">Dosen</th>
                <th class="min-w-100px">Status</th>
                <th class="rounded-end"></th>
              </tr>
            </template>

            <template #tbody>
              <tr v-for="(item, i) in daftar_mata_kuliah" :key="i">
                <td class="text-center align-middle">{{ i + 1 }}</td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.nama_mata_kuliah }}
                  </span>
                  <span class="text-muted text-muted d-block fs-8">
                    {{ item.kode_mata_kuliah }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.kelas }}
                  </span>
                  <span class="text-muted text-muted d-block fs-8">
                    {{ item.nama_program_studi }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.nama }}
                  </span>
                  <span class="text-muted text-muted d-block fs-8">
                    {{ item.nidn }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bold d-block mb-1 fs-7">
                    Disetujui Mahasiswa: {{ item.disetujui }}/{{ item.jumlah }}
                  </span>
                  <span class="text-dark fw-bold d-block mb-1 fs-7">
                    Dosen Unggah RPS:
                    <span v-if="item.is_upload" class="text-success">
                      <icon-done /> |
                      {{
                        $date(item.upload_at)
                          .locale("id")
                          .format(" D-MM-YYYY HH:mm")
                      }}
                    </span>
                    <span v-else class="text-danger"><icon-cross /></span>
                  </span>
                  <span class="text-dark fw-bold d-block mb-1 fs-7">
                    Validasi Program Studi:
                    <span v-if="item.is_validation" class="text-success">
                      <icon-done /> | {{ item.validation_at }}
                    </span>
                    <span v-else class="text-danger"><icon-cross /></span>
                  </span>
                </td>
                <td class="align-middle">
                  <a
                    class="mt-5 ms-md-3 mt-xl-0 btn btn-sm btn-secondary btn-active-primary"
                    @click="goDetailKontrakPerkuliahan(item)"
                  >
                    Detail
                  </a>
                  <a
                    v-if="item.doc_rps"
                    :href="`${baseUrl}public/${item.doc_rps}`"
                    target="_blank"
                    class="mt-5 ms-md-3 mt-md-0 btn btn-sm btn-secondary btn-active-primary"
                  >
                    Lihat RPS
                  </a>
                  <a
                    class="mt-5 ms-md-3 mt-xl-0 btn btn-sm btn-secondary btn-active-primary"
                    v-if="!item.is_validation && item.is_upload"
                    data-bs-toggle="modal"
                    data-bs-target="#validasi_modal"
                    @click="selected_id = item.id"
                  >
                    Validasi
                  </a>
                  <a
                    class="mt-5 ms-md-3 mt-xl-0 btn btn-sm btn-secondary btn-active-primary"
                    v-if="item.is_validation"
                    data-bs-toggle="modal"
                    data-bs-target="#buka_modal"
                    @click="selected_id = item.id"
                  >
                    Buka
                  </a>
                  <a
                    class="mt-5 ms-md-3 mt-xl-0 btn btn-sm btn-secondary btn-active-primary"
                    @click="postDownloadKontrakPerkuliahan(item)"
                    v-if="item.is_upload"
                  >
                    Unduh Kontrak
                  </a>
                </td>
              </tr>
            </template>
          </table-basic>
        </template>
      </card-basic>
      <!-- START VALIDASI MODAL -->
      <div class="modal fade" tabindex="-1" id="validasi_modal">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Kontrak Perkuliahan</h5>

              <div
                class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <icon-close />
              </div>
            </div>

            <div class="modal-body">
              <p>
                Apakah anda yakin untuk melakukan validasi kontrak perkuliahan?
              </p>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                Tutup
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="postValidasiProdi(selected_id)"
              >
                Validasi
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- END VALIDASI MODAL -->
      <!-- START BUKA MODAL -->
      <div class="modal fade" tabindex="-1" id="buka_modal">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Kontrak Perkuliahan</h5>

              <div
                class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <icon-close />
              </div>
            </div>

            <div class="modal-body">
              <p>Apakah anda yakin untuk membuka perkuliahan ini?</p>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                Tutup
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="postBukaProdi(selected_id)"
              >
                Buka
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- END BUKA MODAL -->
    </template>
  </container-app>
</template>

<script>
// API
import { getAcademicYearsLecturer } from "@/apis/kontrakPerkuliahan";

import {
  getMataKuliahProdi,
  getListProdi,
  postValidasiKontrakProdi,
  postBukaKontrakProdi,
  postDownloadDokumenKontrak,
} from "@/apis/kontrakPerkuliahanBaru";

export default {
  components: {},
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      container_loading: false,
      tahun_semester: [],
      daftar_mata_kuliah: [],
      daftar_prodi: [],
      selected_tahun_semester: "",
      prodi: "",
      keyword: "",
      selected_id: null,
    };
  },

  async mounted() {
    await this.fetchTahunSemester();
    await this.fetchDaftarProdi();
    await this.fetchDosenMataKuliah(
      this.keyword,
      this.prodi,
      this.selected_tahun_semester
    );
  },

  methods: {
    async fetchTahunSemester() {
      try {
        const response = await getAcademicYearsLecturer();
        this.tahun_semester = response.data.data;
        this.selected_tahun_semester = response.data.data[0].thsms;
      } catch (err) {
        console.error(err);
      }
    },

    async fetchDaftarProdi() {
      try {
        const { data: response } = await getListProdi();
        this.daftar_prodi = response.data;
        this.prodi = response.data[0].id;
      } catch (err) {
        console.error(err);
      }
    },
    async onTahunSemesterChange(event) {
      await this.fetchDosenMataKuliah(
        this.keyword,
        this.prodi,
        event.target.value
      );
    },

    async onProdiChange(event) {
      await this.fetchDosenMataKuliah(
        this.keyword,
        event.target.value,
        this.selected_tahun_semester
      );
    },

    async fetchDosenMataKuliah(keyword, prodi, tahun_semester) {
      try {
        this.container_loading = true;
        const params = new URLSearchParams();
        if (keyword) {
          params.append("query", keyword);
        }
        if (prodi) {
          params.append("kode_program_studi", prodi);
        }
        params.append("tahun_semester", tahun_semester);

        const { data: response } = await getMataKuliahProdi(params);
        if (response.success) {
          this.daftar_mata_kuliah = response.data;
        } else if (!response.success) {
          this.daftar_mata_kuliah = [];
        }
        this.container_loading = false;
      } catch (err) {
        this.container_loading = false;
      }
    },

    async postValidasiProdi(id) {
      try {
        const { data: response } = await postValidasiKontrakProdi({ id });
        if (response.success) {
          Swal.fire({
            text: response.message,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          $("#validasi_modal").modal("hide");
          await this.fetchDosenMataKuliah(
            this.keyword,
            this.prodi,
            this.selected_tahun_semester
          );
        }
      } catch (err) {
        console.error(err);
      }
    },

    async postBukaProdi(id) {
      try {
        const { data: response } = await postBukaKontrakProdi({ id });
        if (response.success) {
          Swal.fire({
            text: response.message,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          $("#buka_modal").modal("hide");
          await this.fetchDosenMataKuliah(
            this.keyword,
            this.prodi,
            this.selected_tahun_semester
          );
        }
      } catch (err) {
        console.error(err);
      }
    },

    async postDownloadKontrakPerkuliahan(item) {
      try {
        item.download_loading = "on";

        const response = await postDownloadDokumenKontrak({
          tahun_semester: item.tahun_semester,
          kode_mata_kuliah: item.kode_mata_kuliah,
          kelas: item.kelas,
        });

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Kontrak-Perkuliahan-${item.tahun_semester}-${item.kode_mata_kuliah}-${item.nama_mata_kuliah}-${this.$route.query.kelas}.pdf`
        );
        document.body.appendChild(link);
        link.click();

        item.download_loading = "off";
      } catch (error) {
        item.download_loading = "off";
        console.error("Error downloading PDF report:", error);
      }
    },

    goDetailKontrakPerkuliahan(item) {
      this.$router.push({
        path: `/kontrak-perkuliahan-new/detail/${item.id}`,
        query: {
          tahun_semester: item.tahun_semester,
          nama_mata_kuliah: item.nama_mata_kuliah,
          kode_mata_kuliah: item.kode_mata_kuliah,
          program_studi: item.nama_program_studi,
          kelas: item.kelas,
          dosen: item.nama,
          disetujui: item.disetujui,
          jumlah: item.jumlah,
          is_upload: item.is_upload,
          doc_rps: item.doc_rps,
        },
      });
    },
  },
};
</script>
